import React from "react";

const MTCoders = () => {
  return (
    <>
      <div>
        <h1>HI!</h1>
        <h2>HI!</h2>
        <h3>HI!</h3>
        <h4>HI!</h4>
        <h5>HI!</h5>
        <h6>HI!</h6>
      </div>
    </>
  );
};

export default MTCoders;
