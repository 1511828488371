import React, { useState, useEffect, useCallback, useRef } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import BlackButton from "../../BlackButton1";
import TitleAndDirectory from "../HOME/TitleAndDirectory";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "../../App.css";
import MTCoders from "./MTCoders";

type CacheType = {
  [key: string]: string[];
};
const CleanHtmlTextBox: React.FC = () => {
  const handleCopyToClipboard = (emailHTML: string) => {
    navigator.clipboard.writeText(emailHTML);
  };
  const [cleanedHtml, setCleanedHtml] = useState<string>("");

  const sanitizeHtml = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Remove all <meta> tags
    doc.querySelectorAll("meta").forEach((meta) => meta.remove());

    // Remove all inline styles
    doc.querySelectorAll("*").forEach((node) => {
      node.removeAttribute("style");
    });

    // Return cleaned HTML
    // return doc.body.innerHTML;
    return doc.body.innerHTML;
  };

  const replaceEmojis = (html: string): string => {
    return html.replace(
      /<img[^>]*aria-label="([^"]+)"[^>]*>/g,
      (_, emoji) => emoji
    );
  };
  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const htmlContent =
      event.clipboardData.getData("text/html") ||
      event.clipboardData.getData("text/plain");
    const sanitizedHtml = replaceEmojis(sanitizeHtml(htmlContent));
    setCleanedHtml(sanitizedHtml);
  };
  const escapeForJson = (html: string): string => {
    return html
      .replace(/\\/g, "\\\\") // Escape backslashes
      .replace(/"/g, '\\"') // Escape double quotes
      .replace(/\n/g, "\\n") // Escape newlines
      .replace(/\r/g, "\\r"); // Escape carriage returns
  };
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCleanedHtml(event.target.value); // Allow manual editing
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      className={isMobile ? "faq2" : "faq"}
      style={{
        marginBottom: 40,
        padding: "20px",
        marginTop: "40px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {" "}
      <h2 style={{ textAlign: "center", marginBottom: 10 }}>
        Converts Emails Into Blog Format
      </h2>
      {/* Cleaned HTML */}
      <h4 style={{ textAlign: "center", marginBottom: 10 }}>
        1. Paste Email TEXT Into Box [It Makes Editable Version Of Email HTML
        For Code]
      </h4>
      <textarea
        value={sanitizeHtml(cleanedHtml)} // Bind `textarea` value to the state
        onChange={handleChange} // Update state when typing
        onPaste={handlePaste}
        rows={10}
        cols={50}
        style={{
          width: "100%",
          marginBottom: "20px",
          fontFamily: "monospace",
          fontSize: 10,
        }}
        placeholder="Paste your content here..."
      />
      {/* Sanitized Output */}
      <h4 style={{ textAlign: "center" }}>
        2. Copy HTML into blogData.json file (mtc - src - components - BLOG -
        blogData.json)
        <IconButton
          onClick={() => {
            handleCopyToClipboard(escapeForJson(cleanedHtml));
          }}
          sx={{ color: "lightgray" }}
        >
          <ContentCopyIcon />
        </IconButton>
      </h4>
      <div
        dangerouslySetInnerHTML={{ __html: cleanedHtml }}
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "4px",
          backgroundColor: "#f9f9f9",
        }}
      />
    </div>
  );
};

const DevPage = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [devTitle, setDevTitle] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [mylist, setMylist] = useState<string[]>([]);
  const [cache, setCache] = useState<CacheType>({});
  const [fetchError, setFetchError] = useState<string | null>(null);
  const CLIENT_ID = "31d63b14d7704a7fbcfce59e811ff454";
  const CLIENT_SECRET = "8cdeaa6af08e446aa8c2f9f629831abc";

  const handleCopyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
  };

  useEffect(() => {
    const authParameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `grant_type=client_credentials&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
    };
    fetch("https://accounts.spotify.com/api/token", authParameters)
      .then((result) => result.json())
      .then((data) => setAccessToken(data.access_token))
      .catch((error) => console.error("Error fetching access token:", error));
  }, []);

  const fetchAlbumImageUrls = useCallback(async () => {
    const cacheKey = `AlbumOrSong-${devTitle}`;
    if (cache[cacheKey]) {
      setMylist(cache[cacheKey]);
      return;
    }

    const searchParameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(
        `https://api.spotify.com/v1/search?q=artist:${devTitle}&type=album`,
        searchParameters
      );
      if (!response.ok) {
        throw new Error("Failed to fetch albums");
      }
      const data = await response.json();
      const newMylist = data.albums.items.map(
        (album: any) => album.images[0].url
      );
      setMylist(newMylist);
      setCache((prevCache) => ({ ...prevCache, [cacheKey]: newMylist }));
      setFetchError(null);
    } catch (error) {
      console.error("Error fetching albums:", error);
      setFetchError("Failed to fetch album images. Please try again.");
    }
  }, [accessToken, devTitle, cache]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDevTitle(event.target.value);
  };

  const handleSearchButtonPressed = () => {
    if (devTitle !== "") {
      fetchAlbumImageUrls();
    }
  };

  return (
    <>
      <TitleAndDirectory />
      {isMobile ? <br /> : null}
      <div className={isMobile ? "faq2" : "faq"} style={{ marginBottom: 40 }}>
        <div style={{ textAlign: "center" }}>
          <h2>ALBUM/SONG ART (Courtesy of the Spotify API)</h2>
          <h4>(Enter the artist's name, doesn't work w album/song name)</h4>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={devTitle}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ width: 350, m: 1 }}
            size="small"
          />

          <div style={{ margin: 1 }}>
            <BlackButton
              buttonText={"SEARCH"}
              marg={1}
              high={40}
              onClick={handleSearchButtonPressed}
            />
          </div>
        </div>
      </div>
      {mylist.length > 0 ? (
        <div
          className={isMobile ? "faq2" : "faq"}
          style={{ textAlign: "center" }}
        >
          <div className="dev-album-or-song-art-cont">
            {mylist.map((url, index) => (
              <div className="dev-album-or-song-art-item">
                <img
                  key={index}
                  src={url}
                  alt={`Album ${index}`}
                  width={150}
                  height={150}
                />
                <IconButton
                  onClick={() => {
                    handleCopyToClipboard(url);
                  }}
                  sx={{ color: "lightgray" }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {fetchError && (
        <div style={{ color: "red", textAlign: "center" }}>{fetchError}</div>
      )}
      <CleanHtmlTextBox />
      {/* <MTCoders /> */}
    </>
  );
};

export default DevPage;
